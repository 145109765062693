import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import { Helmet } from "react-helmet";
import GuideSocialWidget from "~sections/featured-story/components/CS-widget/guide-social"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function GuideSocial() {
  return (
    <>
    <Helmet>
    <title>Guide Social Scales Conversations Using We-Connect</title>
    <meta name="description" content="Natalie Stones, founder of Talent Collective and Talent Refinery, shares how We-Connect has helped her boost her engagement and ROI for events."/>
    <meta property="og:title" content="Guide Social Scales Conversations Using We-Connect"/>
    <meta property="og:description" content="Guide social is a video-based marketing agency that helps clients reach more people using We-Connect. Watch our interview with founder Matt Johnston to learn more. "/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/guide-social"/>
    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="Guide Social Scales Conversations Using We-Connect"/>
    <meta property="twitter:description" content="Guide social is a video-based marketing agency that helps clients reach more people using We-Connect. Watch our interview with founder Matt Johnston to learn more. "/>
    <meta property="twitter:image" content="https://we-connect.io/guide-social-scales-conversations-using-we-connect.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <GuideSocialWidget />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}