import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import { SuperTag, Link } from '~components';
import VideoJSOne from '../Videojs/VideoJSOne';

export default function GuideSocialWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-5 col-lg-5 col-md-8 col-xs-10">
    <Widget.Image>
    <VideoJSOne videoId="yms3aeomju" wrapper="wistia-player-container-3"/>
    </Widget.Image>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="Guide Social Scales Conversations Using <br/> We-Connect"/> </Widget.Heading>
        </Widget.Box>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Guide Social</Widget.Company>
        <Widget.Text>Guide Social is a marketing agency focusing on YouTube advertising campaigns and viral branded content partnerships.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Marketing and Advertising</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text> 2 - 10 employees</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara className="rsc"> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart sequences, client management</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-7 col-lg-7 col-md-8 col-xs-10">

    <Widget.ParaTwo>“I’ve used many other platforms that do similar stuff. They’ve either been more expensive, less functional, more confusing, or had worse customer support. Whatever it is, I’ve always come back to We-Connect.” </Widget.ParaTwo>
        <Widget.SubPara> <b>Matt Johnston</b>, founder of Guide Social</Widget.SubPara>

        <Widget.Title as="h2"><b>Finding Success in B2B Prospecting</b></Widget.Title>
        <Widget.Text>Matt Johnston, founder of Guide Social, has had multiple use cases for We-Connect since he started using it over a year ago. 
        </Widget.Text>
        <Widget.Text>
        Primarily, Matt uses We-Connect for his consulting clients. He says, “[We-Connect] is my first line of defense when we’re trying to get B2B sales going.” Matt’s clients come to him looking for leads. In many cases, they’ve tried to run paid ads without much success, or they can’t figure out how to market to their target audience outside of LinkedIn ads.
        </Widget.Text>
        <Widget.Text>
        “It can be confusing,” Matt says. What do you do when you’re looking for leads but the advice from the influencers in your niche doesn’t work? The content you’re posting isn’t drawing in your audience, and paid ads drive up costs.
        </Widget.Text>
        <Widget.Text>
        That’s where We-Connect comes in. Matt said, “We-Connect fills this niche better than any other B2B tool that I’ve ever used. Now, I recommend it to all my clients”
        </Widget.Text>
        <Widget.Text>
        “When you break down the cost of We-Connect to the number of prospects you’re reaching and the value of potential customers, it’s a negligible price to what you’re gonna get.”
        </Widget.Text>

        <Widget.Title as="h2"><b>Scale Your Conversations, Scale Your Sales</b></Widget.Title>
        <Widget.Text>In a recent example, Matt described a big win with one of his non-profit clients. “He was trying to use organic social media to get leads for his Shark-Tank-like invention in the disability space.” After trying and trying with social media, Matt’s client didn’t know what to do. 
        </Widget.Text>
        <Widget.Text>
        So, they automated a We-Connect outreach campaign. “They just knocked it out of the park,” Matt said. “They want to keep this going forever, it’s been huge for their non-profit.”
        </Widget.Text>
        <Widget.Text>
        Matt describes We-Connect as a way to scale your conversations and networking. But, really, “it’s really what you use it for,” Matt says. “If you want to scale your pitches and play the numbers game, you can do that. If you want to have good conversations and expand your network, it’s fantastic for that. If you want to use it to get a job, you should spend the money on something like We-Connect.”
        </Widget.Text>

        <Widget.Title as="h2"><b>The Versatility of Automated Outreach</b></Widget.Title>
        <Widget.Text>Due to the broad range of clients Matt works with, he’s tried We-Connect with all different types of strategies. 
        </Widget.Text>
        <Widget.Text>
        In one experience, Matt's client had fantastic success running a retargeting campaign through We-Connect. Matt explained, “If you have a new offer you want to take to the marketplace, do a big DM campaign to all your connections with a certain job title, and you can hit them all with that offer.” This really enables you to gauge the interest of a new offer with your warm audience.
        </Widget.Text>
        <Widget.Text>
        For content marketers, Matt thinks We-Connect is a great tool to expand your reach. He describes one of his strategies, saying, “You can send out a massive number of invitations to your ideal customers. Once you start having conversations with them, they’re going to start seeing your content in their feeds.” It’s a great way to build your audience with real relationships.
        </Widget.Text>
        <Widget.Text>
        No matter how Matt’s agency uses We-Connect, it always comes back to relationship building. Matt said, “The best use case for We-Connect is really to just start conversations with people that you would not have otherwise had any way to get in touch with.” 
        </Widget.Text>
        <Widget.Text>
        For leads that don’t respond to ads or answer cold emails, you can get in front of them with We-Connect. “Sure, you can do this manually, but one, who has the time, and two, you’d get capped on the invite limit much sooner,” said Matt.
        </Widget.Text>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}